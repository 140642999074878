export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    if (sessionStorage.getItem('delegate_access_account')) {
      return navigateTo('/account/dashboard')
    } else {
      return;
    }
  }
});



